var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import axios from 'axios';
import { handleApiError } from '../../../../services/util/errorHandler';
import { List, Button, Card, Row, Typography, Col } from 'antd';
import { ArrowRightOutlined } from '@ant-design/icons';
import { withRouter } from 'react-router';
import styles from './styles.module.css';
import { trackPageWithGoogleAnalytics } from '../../../../services/util/googleAnalytics';
import { API_URL } from '../../../../contants';
var Items = /** @class */ (function (_super) {
    __extends(Items, _super);
    function Items(props) {
        var _this = _super.call(this, props) || this;
        _this.fetchItems = function (pageNo) {
            if (pageNo === void 0) { pageNo = 1; }
            var pageNumber = pageNo - 1;
            _this.setState({ isLoading: true });
            axios
                .get(API_URL + "/academix/sub-categories/" +
                (_this.SubCategoryId + "/items?pageNumber=" + pageNumber + "&pageSize=" + _this.pageSize))
                .then(function (result) {
                if (result.status == 200) {
                    var pagination = __assign({}, _this.state.pagination);
                    pagination.current = pageNo;
                    pagination.total = result.data.totalElements;
                    _this.setState({
                        isLoading: false,
                        items: result.data.content,
                        pagination: pagination,
                    });
                }
                else {
                    throw new Error();
                }
            })
                .catch(function (error) {
                handleApiError(error, 'Something went wrong when trying to load items');
                _this.setState({ isLoading: false });
            });
        };
        _this.pageSize = 8;
        _this.SubCategoryId = _this.props.match.params.subCategoryId;
        _this.props.setCurrent(_this.SubCategoryId);
        _this.state = {
            isLoading: false,
            items: [],
            pagination: {
                current: 1,
                total: 0,
                pageSize: _this.pageSize,
            },
        };
        return _this;
    }
    Items.prototype.componentDidMount = function () {
        this.fetchItems();
        trackPageWithGoogleAnalytics();
    };
    Items.prototype.componentDidUpdate = function () {
        if (this.SubCategoryId !== this.props.match.params.subCategoryId) {
            this.SubCategoryId = this.props.match.params.subCategoryId;
            this.fetchItems();
            trackPageWithGoogleAnalytics();
        }
    };
    Items.prototype.render = function () {
        var Paragraph = Typography.Paragraph, Title = Typography.Title;
        return (React.createElement(Col, { className: styles.cardContainer },
            React.createElement(List, { pagination: {
                    onChange: this.fetchItems,
                    pageSize: this.pageSize,
                    total: this.state.pagination.total,
                }, grid: {
                    gutter: 16,
                    xs: 1,
                    sm: 2,
                    md: 4,
                    lg: 4,
                    xl: 4,
                    xxl: 4,
                }, loading: this.state.isLoading, itemLayout: "horizontal", dataSource: this.state.items, renderItem: function (item) { return (React.createElement(List.Item, { key: item.id },
                    React.createElement(Card, null,
                        React.createElement(Title, { level: 4 }, item.name),
                        React.createElement(Paragraph, { ellipsis: { rows: 8, expandable: true } }, item.description),
                        React.createElement(Row, { className: styles.cardRounded },
                            React.createElement(Button, { href: item.link, type: "primary", target: "_blank", shape: "circle", size: 'large' },
                                React.createElement(ArrowRightOutlined, null)))))); } })));
    };
    return Items;
}(React.Component));
export default withRouter(Items);
