var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React from 'react';
import { Row, Col, Typography } from 'antd';
import styles from './styles.module.css';
import heroImg from './hero.jpg';
import logo from '../../../public/academix-logo.png';
import Categories from './components/catogories';
import SLEFLogo from './SLEFLogo.png';
var Title = Typography.Title;
var Home = /** @class */ (function (_super) {
    __extends(Home, _super);
    function Home() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Home.prototype.render = function () {
        return (React.createElement(Row, null,
            React.createElement(Col, { md: 0, lg: 2 }),
            React.createElement(Col, { md: 24, lg: 21 },
                React.createElement(Row, { className: styles.mainContent },
                    React.createElement(Col, { md: 10, className: styles.descriptionContent },
                        React.createElement("img", { src: logo, alt: "Academix Logo", className: styles.logo }),
                        React.createElement("p", { className: styles.description }, "AcadeMix aims to curate a collection of free education resources available for Sri Lankan students to maximise their learning potential from Primary through to Post Graduate education and beyond."),
                        React.createElement("p", { className: styles.descriptionContact },
                            "If you have a website, blog or channel that features free education content to support curriculum in Sri Lanka, do share with us at\u00A0",
                            React.createElement("a", { href: "mailto:info@sefglobal.org" }, "info@sefglobal.org."),
                            " We also welcome any organisation that is keen on collaborating with us to join forces."),
                        React.createElement(Row, null,
                            React.createElement(Title, { level: 4 }, "AcadeMiX is built in collaboration with,")),
                        React.createElement(Row, null,
                            React.createElement(Col, { md: 6, lg: 3 },
                                React.createElement("img", { src: SLEFLogo, alt: "SLEF Logo", width: 220 })))),
                    React.createElement(Col, { md: 2 }),
                    React.createElement(Col, { md: 12 },
                        React.createElement("img", { width: "80%", src: heroImg, alt: "Hero image" })))),
            React.createElement(Categories, null)));
    };
    return Home;
}(React.Component));
export default Home;
