var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React from 'react';
import { Card, Col, Row } from 'antd';
import styles from './styles.css';
import axios from 'axios';
import { handleApiError } from '../../../../services/util/errorHandler';
import { Link } from 'react-router-dom';
import { BookOutlined } from '@ant-design/icons';
import { trackPageWithGoogleAnalytics } from '../../../../services/util/googleAnalytics';
import { API_URL } from '../../../../contants';
var Categories = /** @class */ (function (_super) {
    __extends(Categories, _super);
    function Categories(props) {
        var _this = _super.call(this, props) || this;
        _this.state = {
            categories: [],
        };
        return _this;
    }
    Categories.prototype.componentDidMount = function () {
        var _this = this;
        axios
            .get(API_URL + "/academix/categories")
            .then(function (result) {
            if (result.status == 200) {
                _this.setState({
                    categories: result.data,
                });
            }
            else {
                throw new Error();
            }
        })
            .catch(function (error) {
            handleApiError(error, 'Something went wrong when trying to load categories');
        });
        trackPageWithGoogleAnalytics();
    };
    Categories.prototype.render = function () {
        return (React.createElement(Col, { md: 20, offset: 2 },
            React.createElement(Row, null, this.state.categories.map(function (category) {
                // Replace spaces and slashes from the category name to include it on the URL
                var categoryName = category.name
                    .trim()
                    .replace(/\s+|\//g, '-')
                    .toLowerCase();
                return (React.createElement(Col, { key: category.id, md: 8 },
                    React.createElement(Link, { to: "/" + category.id + "/" + categoryName },
                        React.createElement(Card, { hoverable: true, className: styles.card, bordered: true },
                            React.createElement("h1", { className: styles.categoryName },
                                React.createElement(BookOutlined, null),
                                " ",
                                category.name)))));
            }))));
    };
    return Categories;
}(React.Component));
export default Categories;
