var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import 'antd/dist/antd.less';
import RouteWithSubRoutes from '../RouteWithSubRoutes';
import { BrowserRouter as Router, Switch, Redirect } from 'react-router-dom';
import { Layout, Menu } from 'antd';
import styles from './styles.css';
import seflogo from '../../../public/logo.png';
import ReactGA from 'react-ga';
import Moment from 'react-moment';
import { InstagramFilled, FacebookFilled, TwitterSquareFilled, LinkedinFilled, MenuOutlined, } from '@ant-design/icons';
// Initialize google analytics using the tracking code
ReactGA.initialize('UA-167873271-2');
var Header = Layout.Header, Content = Layout.Content, Footer = Layout.Footer;
var date = new Date();
var App = /** @class */ (function (_super) {
    __extends(App, _super);
    function App(props) {
        return _super.call(this, props) || this;
    }
    App.prototype.render = function () {
        return (React.createElement(Router, null,
            React.createElement(Layout, { style: { background: '#fff' } },
                React.createElement(Header, { className: styles.header },
                    React.createElement(Menu, { theme: "light", mode: "horizontal", overflowedIndicator: React.createElement(MenuOutlined, null) },
                        React.createElement(Menu.Item, { key: "0" },
                            React.createElement("a", { href: "https://sefglobal.org/" },
                                React.createElement("img", { className: styles.logo, src: seflogo, alt: "SEF Logo" }))),
                        React.createElement(Menu.Item, { key: "1" },
                            React.createElement("a", { href: "https://sefglobal.org/" }, "Home")),
                        React.createElement(Menu.Item, { key: "2" },
                            React.createElement("a", { href: "https://sefglobal.org/#projects" }, "Projects")),
                        React.createElement(Menu.Item, { key: "3" },
                            React.createElement("a", { href: "https://sefglobal.org/team.html" }, "Team")),
                        React.createElement(Menu.Item, { key: "4" },
                            React.createElement("a", { href: "https://sefglobal.medium.com/" }, "Blog")),
                        React.createElement(Menu.Item, { key: "5" },
                            React.createElement("a", { href: "https://sefglobal.org/join-us.html" }, "Join Us")),
                        React.createElement("div", { className: styles.iconList },
                            React.createElement("a", { href: "https://www.facebook.com/sustainableeducationfoundation/" },
                                React.createElement(FacebookFilled, { className: styles.icon })),
                            React.createElement("a", { href: "https://twitter.com/goasksef" },
                                React.createElement(TwitterSquareFilled, { className: styles.icon })),
                            React.createElement("a", { href: "https://www.linkedin.com/company/sefglobal/" },
                                React.createElement(LinkedinFilled, { className: styles.icon })),
                            React.createElement("a", { href: "https://www.instagram.com/sefglobal/" },
                                React.createElement(InstagramFilled, { className: styles.icon }))))),
                React.createElement(Content, { className: styles.content },
                    React.createElement(Switch, null,
                        React.createElement(Redirect, { exact: true, from: "/dashboard", to: "/dashboard/home" }),
                        this.props.routes.map(function (route) { return (React.createElement(RouteWithSubRoutes, __assign({ key: route.path }, route))); }))),
                React.createElement(Footer, { className: styles.footer },
                    "\u00A9 ",
                    React.createElement(Moment, { format: "YYYY" }, date),
                    React.createElement("a", { className: styles.footerLink, href: "https://sefglobal.org/" }, "Sustainable Education Foundation - SEF")))));
    };
    return App;
}(React.Component));
export default App;
