var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React from "react";
import styles from "./styles.module.css";
import axios from "axios";
import { handleApiError } from "../../services/util/errorHandler";
import { Switch } from "react-router";
import { Button, Col, PageHeader, Row, Typography } from "antd";
import { Link, Route, BrowserRouter as Router, withRouter, Redirect, } from "react-router-dom";
import Items from "./scenes/Items";
import { API_URL } from "../../contants";
var SubCategories = /** @class */ (function (_super) {
    __extends(SubCategories, _super);
    function SubCategories(props) {
        var _this = _super.call(this, props) || this;
        _this.fetchSubCategories = function () {
            axios
                .get(API_URL + "/academix/categories/" + _this.CategoryId + "/sub-categories")
                .then(function (result) {
                if (result.status == 200) {
                    _this.setState({
                        subCategories: result.data,
                    });
                }
                else {
                    throw new Error();
                }
            })
                .catch(function (error) {
                handleApiError(error, "Something went wrong when trying to load subcategories");
            });
        };
        _this.fetchCategoryDetails = function () {
            axios
                .get(API_URL + "/academix/categories/" + _this.CategoryId)
                .then(function (result) {
                if (result.status == 200) {
                    _this.setState({
                        category: result.data,
                    });
                }
                else {
                    throw new Error();
                }
            })
                .catch(function (error) {
                handleApiError(error, "Something went wrong when trying to load category details");
            });
        };
        _this.setCurrent = function (subCategoryId) {
            _this.setState({
                current: subCategoryId,
            });
        };
        _this.onBack = function () {
            _this.props.history.push("/");
        };
        _this.CategoryId = _this.props.match.params.categoryId;
        _this.state = {
            subCategories: [],
            category: null,
            current: "",
        };
        return _this;
    }
    SubCategories.prototype.componentDidMount = function () {
        this.fetchSubCategories();
        this.fetchCategoryDetails();
        // Scroll to the top when the page is loaded
        document.body.scrollTop = 0; // For Safari
        document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    };
    SubCategories.prototype.render = function () {
        var _this = this;
        var Title = Typography.Title;
        var title = "";
        if (this.state.category != null) {
            title = this.state.category.name;
        }
        // Replace spaces and slashes from the category name to include it on the URL
        var categoryName = title
            .trim()
            .replace(/\s+|\//g, "-")
            .toLowerCase();
        return (React.createElement(Router, null,
            React.createElement(Row, { className: styles.mainContent },
                React.createElement(Col, { md: 20, className: styles.headerWrapper },
                    React.createElement(PageHeader, { className: styles.pageHeader, onBack: this.onBack, title: React.createElement(Title, { className: styles.pageTitle, level: 2 }, title) }))),
            React.createElement(Row, { className: styles.mainContent },
                React.createElement(Col, { md: 20 },
                    React.createElement(Row, { className: styles.subCategoryWrapperRow }, this.state.subCategories.map(function (subCategory) {
                        // Replace spaces and slashes from the subcategory name to include it on the URL
                        var subCategoryName = subCategory.name
                            .trim()
                            .replace(/\s+|\//g, "-")
                            .toLowerCase();
                        return (React.createElement(Button, { key: subCategory.id, className: styles.subCategoryButton, shape: "round", type: _this.state.current == subCategory.id.toString()
                                ? "primary"
                                : undefined, size: "large", onClick: function () { return _this.setCurrent(subCategory.id.toString()); } },
                            React.createElement(Link, { to: "/" + _this.CategoryId + "/" + categoryName + "/" + subCategory.id + "/" + subCategoryName }, subCategory.name)));
                    })))),
            React.createElement(Row, { className: styles.mainContent },
                React.createElement(Col, { md: 20 },
                    React.createElement(Switch, null,
                        React.createElement(Route, { path: "/:categoryId/:categoryName/:subCategoryId/:subCategoryName" },
                            React.createElement(Items, { setCurrent: this.setCurrent })),
                        React.createElement(Route, { exact: true, path: "/:categoryId/:categoryName" }, this.state.subCategories.length > 0 && (React.createElement(Redirect, { to: this.props.match.url + "/" + this.state.subCategories[0].id + "/" + this.state.subCategories[0].name
                                .trim()
                                .replace(/\s+|\//g, "-")
                                .toLowerCase() }))))))));
    };
    return SubCategories;
}(React.Component));
export default withRouter(SubCategories);
